import { Helpers, SizedContainer } from 'lama-games-engine';
import { Text, TextStyle } from 'pixi.js';
import Game from '../starter';
import { H3 } from './Headings';
const paragraphStyle = new TextStyle({
    fill: "#ffffff",
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: 32,
    align: "center",
    wordWrap: true
});
export class GameRulesThree extends SizedContainer {
    constructor(width, height) {
        super(width, height, false);
        const scatter = new H3(`This is the SCATTER symbol`, { fill: "#F5BD46" });
        scatter.anchor.set(0.5, 0);
        scatter.position.set(width / 2, -6);
        this.addChild(scatter);
        const symbolName = Game.Engine.SETTINGS.GAME_SYMBOLS_MAP[8];
        const scatterImage = Helpers.animatedSpriteFromFrames(`/textures/animations/${symbolName}.json`);
        const scale = Math.min(120 / scatterImage.width, 120 / scatterImage.height);
        scatterImage.loop = false;
        scatterImage.animationSpeed = 0;
        scatterImage.scale.x = scatterImage.scale.y = scale;
        scatterImage.position.set(0, scatter.y + scatter.height - 20);
        this.addChild(scatterImage);
        const scatterText = new Text('It can appear on all reels. It does not disappear during tumbles. ' +
            'Whenever the SCATTER symbol hits, it can have a random  multiplier ' +
            'on it. At the end of the spin and all tumbles resulted from it, ' +
            'the multipliers applies to the total win awarded. If two or ' +
            'more multipliers are present, they add to each other. ' +
            '\nPossible multiplier values are: 2x, 3x, 4x, 5x, 6x, 8x, 10x, 12x, ' +
            '14x, 15x, 16x, 18x, 20x, 22x, 25x, 30x, 35x, 40x, 50x, 60x, ' +
            '80x, 100x, 150x, 200x, 300x, 400x, 500x.', Object.assign(Object.assign({}, paragraphStyle), { align: "left", fontSize: 12, wordWrapWidth: width - scatterImage.width - 20 }));
        scatterText.position.set(scatterImage.x + scatterImage.width + 20, scatter.y + scatter.height);
        this.addChild(scatterText);
        const tumble = new H3(`Tumble feature`, { fill: "#F5BD46" });
        tumble.anchor.set(0.5, 0);
        tumble.position.set(width / 2, scatterText.y + scatterText.height + 30);
        this.addChild(tumble);
        const tumbleText = new Text('The TUMBLE FEATURE means that after every spin, winning combinations ' +
            'are paid and all winning symbols disappear. The remaining symbols ' +
            'fall to the bottom of the screen and the empty positions are ' +
            'replaced with new symbols coming from above. ' +
            '\nTumbling will continue untill no more winning combinations appear as a result of a tumble. ' +
            '\nAll wins are added to the player’s balance after all of the tumbles resulted from a base spin have been played.', Object.assign(Object.assign({}, paragraphStyle), { fontSize: 12, wordWrapWidth: width - 40 }));
        tumbleText.anchor.set(0.5, 0);
        tumbleText.position.set(width / 2, tumble.y + tumble.height);
        this.addChild(tumbleText);
        const free = new H3(`Free spins`, { fill: "#F5BD46" });
        free.anchor.set(0.5, 0);
        free.position.set(width / 2, tumbleText.y + tumbleText.height + 30);
        this.addChild(free);
        const freeText = new Text('Hit 4 or more SCATTER symbols anywhere on the screen to trigger for FREE SPINS ' +
            'feature and win 10 free spins. During the round, whenever a multiplier hits on ' +
            'the SCATTER symbol and the spin results in a win, its value gets collected in ' +
            'a TOTAL MULTIPLIER meter. For the whole duration of the round, whenever any new ' +
            'SCATTER with multiplier hits and results in a win, the value of the TOTAL ' +
            'MULTIPLIER is also added to multiply the win. ' +
            '\nHit 4 or more SCATTER symbols anywhere on the screen during the round, on the ' +
            'same free spin, to retrigger the feature and win 5 additional free spins. ' +
            '\nSpecial reels are in play during the feature.', Object.assign(Object.assign({}, paragraphStyle), { fontSize: 12, wordWrapWidth: width - 40 }));
        freeText.anchor.set(0.5, 0);
        freeText.position.set(width / 2, free.y + free.height);
        this.addChild(freeText);
    }
}
