var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Helpers, Modal } from 'lama-games-engine';
import { Container, Text, SimpleRope, Point } from 'pixi.js';
import Game from "../starter";
export class FreeSpinsModal {
    constructor(options, nextGameState = 'idle') {
        this.options = options;
        this.nextGameState = nextGameState;
        this.container = new Container();
        this.modal = new Modal(options);
        this.background = Helpers.getSpriteFromTextureURL(`free-spins.png`);
        const starSprite = Helpers.getSpriteFromTextureURL('star.png');
        this.stars = [starSprite];
    }
    static presentModal(options) {
        const win = Game.Engine.Application.gameData.lastWin;
        return (new FreeSpinsModal(options)).setText(`10`);
    }
    setRelativeTo(container) {
        this.relativeTo = container;
        return this;
    }
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }
    describeArc(x, y, radius, startAngle, endAngle) {
        var start = this.polarToCartesian(x, y, radius, endAngle);
        var end = this.polarToCartesian(x, y, radius, startAngle);
        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
        var d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
        return d;
    }
    setText(text) {
        this.text = new Text(text, {
            fontFamily: 'Montserrat',
            fontSize: 120,
            fontWeight: 'bold',
            align: 'center',
            fill: '#ffc700',
            // stroke: "#a900e3",
            // strokeThickness: 6,
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#a102f2',
            dropShadowDistance: 10
        });
        this.text.anchor.set(0.5);
        this.text.position.set(this.background.width / 2, this.background.height + 290);
        this.typeText = new Text(`Free Spins`, {
            fontFamily: 'Montserrat',
            fontSize: 150,
            fontWeight: 'bold',
            align: 'center',
            fill: '#eaece3',
            // stroke: "#a900e3",
            // strokeThickness: 6,
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#0a7cec',
            dropShadowDistance: 10,
        });
        this.typeText.anchor.set(0.5);
        this.typeText.position.set(this.background.width / 2, this.background.height + 100);
        const testText = new Text(`Free Spins`, {
            fontFamily: 'Montserrat',
            fontSize: 150,
            fontWeight: 'bold',
            align: 'center',
            fill: '#eaece3',
            // stroke: "#a900e3",
            // strokeThickness: 6,
            lineJoin: "round",
            dropShadow: true,
            dropShadowAlpha: 1,
            dropShadowAngle: 90,
            dropShadowBlur: 0,
            dropShadowColor: '#0a7cec',
            dropShadowDistance: 10,
        });
        const points = [];
        for (let i = 0; i < 20; i++) {
            const y = i < 10 ? i : 20 - i;
            points.push(new Point(i * testText.width / 20, y * 10));
        }
        const newPoints = [];
        for (let i = 0; i <= 20; i++) {
            const point = this.polarToCartesian(500, 1470, 1460, ((30 * i) / 20) - 15);
            const pivotPoint = new Point(point.x, point.y);
            newPoints.push(pivotPoint);
        }
        const strip = new SimpleRope(testText.texture, newPoints);
        const testContainer = new Container();
        // testContainer.x = 400;
        testContainer.y = 300;
        this.container.addChild(testContainer);
        testContainer.addChild(strip);
        return this;
    }
    setStarts() {
        const star = this.stars[0];
        star.scale.set(0.6);
        star.anchor.set(0.5);
        star.position.set(this.background.width / 2, (this.background.height / 2) - (star.height / 2) - (this.background.height * 0.07));
        this.container.addChild(star);
    }
    positionBackground() {
        this.container.addChild(this.background);
    }
    testPosition() {
        var _a, _b, _c, _d;
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            // this.modal.content.pivot.set(window.innerWidth / 2, window.innerHeight / 2);
            const offset = ((_b = (_a = this.relativeTo) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : 0) * 0.12;
            this.modal.content.position.set(((_d = (_c = this.relativeTo) === null || _c === void 0 ? void 0 : _c.x) !== null && _d !== void 0 ? _d : 0), 0);
        }
    }
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            // this.container.addChild(this.contentHolder);
            this.positionBackground();
            this.setStarts();
            if (this.text) {
                this.container.addChild(this.text);
            }
            yield this.modal
                .addContentChild(this.container)
                .show();
        });
    }
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.hide();
        });
    }
}
