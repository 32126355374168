import { SizedContainer } from 'lama-games-engine';
import { Container, Text, TextStyle } from 'pixi.js';
import { H2 } from './Headings';
const paragraphStyle = new TextStyle({
    fill: "#ffffff",
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: 16,
    align: "center",
    whiteSpace: "normal",
    wordWrap: true
});
const contents = [
    `
  50 to 74 points pays 0.2x total bet\n
  75 to 99 points pays 0.3x total bet\n
  100 to 124 points pays 0.4x total bet\n
  125 to 149 points pays 0.5x total bet\n
  150 to 199 points pays 0.6x total bet\n
  200 to 249 points pays 0.8x total bet\n
  250 to 299 points pays 1x total bet\n
  300 to 349 points pays 1.25x total bet\n
  350 to 399 points pays 1.5x total bet\n
  400 to 499 points pays 1.75x total bet\n
  500 to 599 points pays 2.5x total bet\n
  600 to 699 points pays 3.25x total bet
  `,
    `
  700 to 799 points pays 4x total bet\n
  800 to 899 points pays 4.75x total bet\n
  900 to 1099 points pays 5.5x total bet\n
  1100 to 1299 points pays 7x total bet\n
  1300 to 1499 points pays 8.5x total bet\n
  1500 to 1699 points pays 10.5x total bet\n
  1700 to 1899 points pays 12x total bet\n
  1900 to 2199 points pays 13.5x total bet\n
  2200 to 2499 points pays 15x total bet\n
  2500 to 2799 points pays 17.5x total bet\n
  2800 to 3099 points pays 20x total bet\n
  3100 to 3399 points pays 22.5x total bet
  `,
    `
  700 to 799 points pays 4x total bet\n
  800 to 899 points pays 4.75x total bet\n
  900 to 1099 points pays 5.5x total bet\n
  1100 to 1299 points pays 7x total bet\n
  1300 to 1499 points pays 8.5x total bet\n
  1500 to 1699 points pays 10.5x total bet\n
  1700 to 1899 points pays 12x total bet\n
  1900 to 2199 points pays 13.5x total bet\n
  2200 to 2499 points pays 15x total bet\n
  2500 to 2799 points pays 17.5x total bet\n
  2800 to 3099 points pays 20x total bet\n
  3100 to 3399 points pays 22.5x total bet
  `
];
export class GameRulesTwo extends SizedContainer {
    constructor(width, height) {
        super(width, height, false);
        const gameRules = new H2(`Game rules`, { fill: "#F5BD46" });
        gameRules.anchor.set(0.5, 0);
        gameRules.position.set(width / 2, -6);
        this.addChild(gameRules);
        const spin = new Text(`
        During the spin and all tumbles resulted from it, the points gotten from winning 
        combinations are collected in a total. After the spin, the game pays an amount 
        depending on the total amount of points collected as follows:
      `, Object.assign(Object.assign({}, paragraphStyle), { wordWrapWidth: width }));
        spin.anchor.set(0.5, 0);
        spin.position.set(width / 2, gameRules.y + gameRules.height);
        this.addChild(spin);
        const textContainer = new Container();
        textContainer.position.set(0, spin.y + spin.height + 40);
        const texts = contents.map((content, index) => {
            const text = new Text(content, Object.assign(Object.assign({}, paragraphStyle), { fontSize: 12, lineHeight: 12, align: "left", wordWrap: false }));
            return text;
        });
        const textTotalWidth = texts.reduce((acc, text) => acc + text.width, 0);
        const freeSpace = width - textTotalWidth;
        const spaceBetween = freeSpace / (texts.length - 1);
        texts.forEach((text, index) => {
            if (!index) {
                text.position.set(0, 0);
            }
            else {
                text.position.set((spaceBetween * index) + (texts.slice(0, index).reduce((acc, text) => acc + text.width, 0)), 0);
            }
            textContainer.addChild(text);
        });
        this.addChild(textContainer);
    }
}
