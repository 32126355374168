import { SizedContainer } from 'lama-games-engine';
import { Graphics } from 'pixi.js';
import { BetSettings } from './BetSettings';
import { H1 } from './Headings';
import { SystemSettingsControls } from './SystemSettingsControls';
export class SystemSettings extends SizedContainer {
    constructor(width, height) {
        super(width, height, false);
        const title = this.initTitle();
        title.position.set(width / 2, 0);
        this.addChild(title);
        const systemSettings = new SystemSettingsControls();
        systemSettings.container.position.set(0, (title.height) + 24);
        this.addChild(systemSettings.container);
        const separator = this.initSeparator(2, systemSettings.container.height);
        separator.position.set((width / 2) + 1, systemSettings.container.y);
        this.addChild(separator);
        const betSettings = new BetSettings();
        betSettings.container.position.set(separator.x + 1 + (width / 2 / 2) - (betSettings.container.width / 2), (height / 2) + (betSettings.container.height / 2));
        this.addChild(betSettings.container);
    }
    initTitle() {
        const title = new H1(`System settings`, { fill: "#F5BD46" });
        title.anchor.set(0.5, 0);
        return title;
    }
    initSeparator(width, height, color = "#F5BD46", x = 0, y = 0) {
        const separator = new Graphics();
        separator.beginFill(color);
        separator.drawRect(x, y, width, height);
        separator.endFill();
        return separator;
    }
}
