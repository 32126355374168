import { Text } from 'pixi.js';
const defaultStyles = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 40,
    fill: "#000000",
    lineJoin: "round",
    stroke: "#000000",
    strokeThickness: 1,
    dropShadow: true,
    dropShadowAlpha: 0.25,
    dropShadowAngle: 90,
    dropShadowBlur: 4,
    dropShadowDistance: 4,
    dropShadowColor: "#000000"
};
class H extends Text {
    constructor(text, style, canvas) {
        super(text, Object.assign(Object.assign({}, defaultStyles), (style || {})), canvas);
    }
}
export class H1 extends H {
    constructor(text, style, canvas) {
        super(text, Object.assign({ fontSize: 40 }, style), canvas);
    }
}
export class H2 extends H {
    constructor(text, style, canvas) {
        super(text, Object.assign({ fontSize: 32 }, style), canvas);
    }
}
export class H3 extends H {
    constructor(text, style, canvas) {
        super(text, Object.assign({ fontSize: 24 }, style), canvas);
    }
}
export class H4 extends H {
    constructor(text, style, canvas) {
        super(text, Object.assign({ fontSize: 16 }, style), canvas);
    }
}
