import { Color, Container, Graphics } from 'pixi.js';
const lineOptionsDefaults = {
    color: new Color([255, 255, 255]),
    filters: null
};
export class Line extends Container {
    constructor(width, height, options) {
        var _a;
        super();
        options = Object.assign(Object.assign({}, lineOptionsDefaults), options);
        const line = new Graphics();
        line.beginFill(options.color);
        line.drawRect(0, 0, width, height);
        line.endFill();
        line.filters = (_a = options.filters) !== null && _a !== void 0 ? _a : null;
        this.addChild(line);
    }
}
export class HorizontalLine extends Line {
    constructor(length, thickness = 1, options) {
        super(length, thickness, options);
    }
}
export class VerticalLine extends Line {
    constructor(length, thickness = 1, options) {
        super(thickness, length, options);
    }
}
