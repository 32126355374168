import { engineGsap, Helpers } from 'lama-games-engine';
import { Container } from 'pixi.js';
let RIGHT_OFFSET = 450;
export class Sweets extends Container {
    constructor() {
        super();
        RIGHT_OFFSET = window.PLATFORM.isMobile ? 450 : 850;
        this.sortableChildren = true;
        this
            .cane()
            .caneCenter()
            .creamGreen()
            .creamPurple()
            .creamWhite()
            .ropeBlue()
            .creamPink()
            .creamBlue()
            .creamWhiteRight()
            .redLollipop()
            .blueLollipop()
            .blueStrippedBall()
            .pinkSolidBall()
            .pinkStrippedBall()
            .pinkStrippedBallRight()
            .iceCream()
            .cherryLeaf()
            .cherryStraight();
    }
    cane() {
        const container = new Container();
        const sprite = Helpers.getSpriteFromTextureURL('cane.png');
        sprite.pivot.set(sprite.width, sprite.height);
        sprite.position.set(sprite.width, sprite.height);
        container.zIndex = 100;
        container.addChild(sprite);
        engineGsap.to(sprite, {
            pixi: {
                rotation: 5
            },
            yoyo: true,
            duration: 3,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(container);
        return this;
    }
    caneCenter() {
        if (window.PLATFORM.isLandscape) {
            return this;
        }
        const container = new Container();
        const sprite = Helpers.getSpriteFromTextureURL('cane.png');
        sprite.pivot.set(sprite.width, sprite.height);
        sprite.position.set(630, 200);
        container.zIndex = 10;
        container.addChild(sprite);
        engineGsap.to(sprite, {
            pixi: {
                rotation: 8
            },
            yoyo: true,
            duration: 3,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(container);
        return this;
    }
    creamGreen() {
        const sprite = Helpers.getSpriteFromTextureURL('cream_green.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = -10;
        sprite.position.set(170, 140);
        sprite.zIndex = 200;
        engineGsap.to(sprite, {
            pixi: {
                angle: -6
            },
            yoyo: true,
            duration: 4,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    creamPurple() {
        const sprite = Helpers.getSpriteFromTextureURL('cream_purple.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = 2;
        sprite.position.set(290, 120);
        sprite.zIndex = 100;
        engineGsap.to(sprite, {
            pixi: {
                angle: -2
            },
            yoyo: true,
            duration: 6,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    creamWhite() {
        const sprite = Helpers.getSpriteFromTextureURL('cream_white.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = -2;
        sprite.position.set(370, 140);
        sprite.zIndex = 200;
        engineGsap.to(sprite, {
            pixi: {
                angle: 2
            },
            yoyo: true,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    ropeBlue() {
        if (window.PLATFORM.isLandscape) {
            return this;
        }
        const sprite = Helpers.getSpriteFromTextureURL('rope_blue_short.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = 0;
        sprite.position.set(500, 130);
        sprite.zIndex = 90;
        this.addChild(sprite);
        return this;
    }
    creamPink() {
        if (window.PLATFORM.isLandscape) {
            return this;
        }
        const sprite = Helpers.getSpriteFromTextureURL('cream_pink.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = -5;
        sprite.position.set(660, 130);
        sprite.zIndex = 200;
        sprite.scale.x *= -1;
        engineGsap.to(sprite, {
            pixi: {
                angle: -2
            },
            yoyo: true,
            duration: 6,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    creamBlue() {
        const sprite = Helpers.getSpriteFromTextureURL('cream_blue.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = 8;
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET - 20 : 0;
        sprite.position.set(790 + x, 120);
        sprite.zIndex = 100;
        engineGsap.to(sprite, {
            pixi: {
                angle: 12
            },
            yoyo: true,
            duration: 4,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    creamWhiteRight() {
        const sprite = Helpers.getSpriteFromTextureURL('cream_white.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.angle = 6;
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET : 0;
        sprite.position.set(900 + x, 170);
        sprite.zIndex = 200;
        sprite.scale.x *= -1;
        engineGsap.to(sprite, {
            pixi: {
                angle: 10
            },
            yoyo: true,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    getLollipop(color = 'red', rotationDirection = 'cw') {
        const container = new Container();
        const lollipop = Helpers.getSpriteFromTextureURL(`lollipop_${color}.png`);
        const stick = Helpers.getSpriteFromTextureURL(`lollipop_stick.png`);
        const randomStartingAngle = Math.floor(Math.random() * (45 - 0 + 1)) + 0;
        const randomDuration = Math.floor(Math.random() * (10 - 6 + 1)) + 6;
        container.sortableChildren = true;
        lollipop.pivot.set(lollipop.width / 2, lollipop.height / 2);
        lollipop.position.set(lollipop.width / 2, lollipop.height / 2);
        lollipop.zIndex = 200;
        lollipop.angle = (rotationDirection == 'cw' ? 0 : 360) + randomStartingAngle;
        stick.pivot.set(stick.width / 2, 0);
        stick.position.set(lollipop.width / 2, lollipop.height - 10);
        stick.zIndex = 100;
        container.addChild(lollipop);
        container.addChild(stick);
        engineGsap.to(lollipop, {
            pixi: {
                angle: (rotationDirection == 'cw' ? 360 : 0) + randomStartingAngle
            },
            yoyo: false,
            duration: randomDuration,
            repeat: -1,
            ease: 'none'
        });
        return container;
    }
    redLollipop() {
        const lollipop = this.getLollipop('red');
        lollipop.pivot.set(lollipop.width / 2, lollipop.height);
        lollipop.angle = 6;
        lollipop.position.set(200, 192);
        lollipop.zIndex = 150;
        lollipop.scale.set(0.8);
        engineGsap.to(lollipop, {
            pixi: {
                angle: 10
            },
            yoyo: true,
            duration: 10,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(lollipop);
        return this;
    }
    blueLollipop() {
        const lollipop = this.getLollipop('blue', 'acw');
        lollipop.pivot.set(lollipop.width / 2, lollipop.height);
        lollipop.angle = -6;
        lollipop.position.set(310, 180);
        lollipop.zIndex = 80;
        lollipop.scale.set(0.6);
        engineGsap.to(lollipop, {
            pixi: {
                angle: -10
            },
            yoyo: true,
            duration: 10,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(lollipop);
        return this;
    }
    blueStrippedBall() {
        const sprite = Helpers.getSpriteFromTextureURL('ball_stripped_blue.png');
        sprite.pivot.set(sprite.width / 2, sprite.height / 2);
        sprite.position.set(180, 50);
        sprite.zIndex = 90;
        sprite.angle = 360;
        engineGsap.to(sprite, {
            pixi: {
                angle: 0
            },
            yoyo: false,
            duration: 10,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    pinkSolidBall() {
        const sprite = Helpers.getSpriteFromTextureURL('ball_solid_pink.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        sprite.position.set(350, 90);
        sprite.zIndex = 90;
        sprite.angle = 10;
        sprite.scale.set(0.7);
        engineGsap.to(sprite, {
            pixi: {
                angle: -10
            },
            yoyo: true,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    pinkStrippedBall() {
        const sprite = Helpers.getSpriteFromTextureURL('ball_stripped_pink.png');
        sprite.pivot.set(sprite.width / 2, sprite.height / 2);
        sprite.position.set(450, 90);
        sprite.zIndex = 80;
        sprite.angle = 360;
        sprite.scale.set(0.9);
        engineGsap.to(sprite, {
            pixi: {
                angle: 0
            },
            yoyo: false,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    pinkStrippedBallRight() {
        const sprite = Helpers.getSpriteFromTextureURL('ball_stripped_pink.png');
        sprite.pivot.set(sprite.width / 2, sprite.height / 2);
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET : 0;
        sprite.position.set(840 + x, 15);
        sprite.zIndex = 80;
        sprite.angle = 0;
        sprite.scale.set(0.9);
        engineGsap.to(sprite, {
            pixi: {
                angle: 360
            },
            yoyo: false,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    iceCream() {
        const sprite = Helpers.getSpriteFromTextureURL('ice_cream.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET : 0;
        sprite.position.set(900 + x, 140);
        sprite.zIndex = 80;
        sprite.angle = 0;
        sprite.scale.set(0.9);
        engineGsap.to(sprite, {
            pixi: {
                angle: 10
            },
            yoyo: true,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    cherryLeaf() {
        const sprite = Helpers.getSpriteFromTextureURL('cherry_leaf.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET : 0;
        sprite.position.set(750 + x, 70);
        sprite.zIndex = 80;
        sprite.angle = 0;
        sprite.scale.set(0.6);
        engineGsap.to(sprite, {
            pixi: {
                angle: -30
            },
            yoyo: true,
            duration: 5,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
    cherryStraight() {
        const sprite = Helpers.getSpriteFromTextureURL('cherry_straight.png');
        sprite.pivot.set(sprite.width / 2, sprite.height);
        const x = window.PLATFORM.isLandscape ? RIGHT_OFFSET : 0;
        sprite.position.set(960 + x, 10);
        sprite.zIndex = 70;
        sprite.angle = 0;
        sprite.scale.set(0.6);
        sprite.scale.x *= -1;
        engineGsap.to(sprite, {
            pixi: {
                angle: 30
            },
            yoyo: true,
            duration: 10,
            repeat: -1,
            ease: 'none'
        });
        this.addChild(sprite);
        return this;
    }
}
