import { TEXT_GRADIENT } from 'pixi.js';
export const options = {
    enabled: true,
    filters: [
    // new DropShadowFilter({
    //   color: 0x000000,
    //   alpha: 0.3,
    //   blur: 2,
    //   offset: {x: 4, y: 4},
    // })
    ]
};
export const labelStyles = {
    trim: true,
    fontFamily: 'Montserrat',
    fontSize: 46,
    fill: '#9906ff',
    fillGradientStops: [
        0,
        1
    ],
    fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
    dropShadow: true,
    dropShadowAlpha: 0.7,
    dropShadowAngle: 45,
    dropShadowBlur: 2,
    dropShadowColor: "#FFF",
    dropShadowDistance: 2
};
