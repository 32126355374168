import { Color, Container, Graphics } from 'pixi.js';
const clickableAreaDefaults = {
    color: new Color([0, 0, 0]),
    debug: false
};
export class ClickableArea extends Container {
    constructor(width, height, options = {}) {
        var _a;
        super();
        options = Object.assign(Object.assign({}, clickableAreaDefaults), options);
        const clickArea = new Graphics();
        clickArea.beginFill(options.color);
        clickArea.drawRect(0, 0, width, height);
        clickArea.endFill();
        clickArea.alpha = ((_a = options.debug) !== null && _a !== void 0 ? _a : false) ? 0.5 : 0;
        this.addChild(clickArea);
    }
}
