import { gsap } from 'gsap';
import { Power1 } from 'gsap/gsap-core';
import { Container, Graphics, Text } from "pixi.js";
import { Helpers } from "lama-games-engine";
const defaults = {
    sprite: 'not-found.json',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    zIndex: 0,
    visible: true
};
export class BonusMultiplier {
    constructor(config) {
        this.config = Object.assign(Object.assign({}, defaults), config);
        this
            .initContainer()
            .initSprite();
        this.timeline = gsap.timeline({
            repeat: 0,
            onComplete: () => {
                console.info(`[BONUS] animation completed`);
            }
        });
    }
    initContainer() {
        this.container = new Container();
        this.container.width = this.config.width;
        this.container.height = this.config.height;
        this.container.zIndex = this.config.zIndex;
        this.container.pivot = {
            x: this.config.width / 2,
            y: this.config.height / 2
        };
        this.container.scale = { x: 0, y: 0 };
        this.setPosition(this.config.x, this.config.y);
        return this;
    }
    initSprite() {
        this.animatedSprite = Helpers.animatedSpriteFromFrames(this.config.sprite);
        this.animatedSprite.anchor.set(0.5);
        this.animatedSprite.x = this.config.width / 2;
        this.animatedSprite.y = this.config.height / 2;
        this.animatedSprite.loop = false;
        this.animatedSprite.animationSpeed = 1;
        const scale = Math.min(this.config.width / this.animatedSprite.width, this.config.height / this.animatedSprite.height);
        this.animatedSprite.scale.x = this.animatedSprite.scale.y = scale;
        this.container.addChild(this.animatedSprite);
        return this;
    }
    initMultiplyContainer(multiplier) {
        this.multiplyContainer = new Container();
        const graph = new Graphics();
        graph.beginFill(0xFFFFFF);
        graph.lineStyle({
            color: 0xBD664B,
            width: 5,
            native: true
        });
        const containerPoint = this.container.getLocalBounds();
        graph.drawCircle(containerPoint.width / 2, containerPoint.height / 2 + 16, 16);
        const text = new Text(`${multiplier}x`, {
            fontFamily: 'Belgrano',
            fontSize: 18,
            fill: 0x3A54A5,
        });
        text.x = (containerPoint.width / 2) - (text.width / 2);
        text.y = (containerPoint.height / 2 + 16) - (text.height / 2);
        this.multiplyContainer.addChild(graph);
        this.multiplyContainer.addChild(text);
        this.container.addChild(this.multiplyContainer);
    }
    appendTo(container) {
        container.addChild(this.container);
        return this;
    }
    setPosition(x, y) {
        this.container.position.set(x, y);
        return this;
    }
    getWidth() {
        return this.container.width;
    }
    getHeight() {
        return this.container.height;
    }
    animate(destination, multiplier = 5) {
        return new Promise(resolve => {
            const duration = 1;
            const x = this.container.x;
            const move = gsap.timeline({
                repeat: 0,
                onComplete: () => {
                    console.info(`[BONUS] move completed`);
                }
            }).add(gsap.to(this.container, {
                duration: duration,
                ease: Power1.easeInOut,
                y: destination.y
            })).add(gsap.timeline({ repeat: 1, yoyo: true })
                .to(this.container, {
                duration: duration / 2,
                ease: Power1.easeIn,
                x: x + 80
            }), "<");
            const scale = gsap.timeline({
                repeat: 0,
                onComplete: () => {
                    console.info(`[BONUS] scale completed`);
                }
            }).add(gsap.to(this.container.scale, {
                duration: duration / 2,
                ease: Power1.easeIn,
                x: 2,
                y: 2
            })).add(gsap.to(this.container.scale, {
                duration: duration / 2,
                ease: Power1.easeOut,
                x: 1,
                y: 1
            }));
            const rotate = gsap.timeline({
                repeat: 1,
                yoyo: true,
                onComplete: () => {
                    console.info(`[BONUS] rotate completed`);
                    this.initMultiplyContainer(multiplier);
                    resolve(null);
                }
            }).to(this.container, {
                duration: duration / 2,
                ease: Power1.easeInOut,
                angle: -20
            });
            this.timeline
                .add(move)
                .add(scale, "<")
                .add(rotate, "<");
        });
    }
}
