import { Helpers, SizedContainer } from 'lama-games-engine';
import { Container, Text, TextStyle } from 'pixi.js';
import Game from '../starter';
import { H2, H4 } from './Headings';
const paragraphStyle = new TextStyle({
    fill: "#ffffff",
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    fontSize: 16,
    align: "center",
    whiteSpace: "normal",
    wordWrap: true
});
export class GameRulesOne extends SizedContainer {
    constructor(width, height) {
        super(width, height, false);
        const gameRules = new H2(`Game rules`, { fill: "#F5BD46" });
        gameRules.anchor.set(0.5, 0);
        gameRules.position.set(width / 2, -6);
        this.addChild(gameRules);
        const winningCombinations = new Text(`
        Winning combinations in the game form from blocks of 
        minimum 5 symbols connected horizontally or vertically. 
        The game is played on a 7x7 grid of symbols
      `, Object.assign(Object.assign({}, paragraphStyle), { wordWrapWidth: width }));
        winningCombinations.anchor.set(0.5, 0);
        winningCombinations.position.set(width / 2, gameRules.y + gameRules.height);
        this.addChild(winningCombinations);
        const powerpays = new H2(`Powerpays feature`, { fill: "#F5BD46" });
        powerpays.anchor.set(0.5, 0);
        powerpays.position.set(width / 2, winningCombinations.y + winningCombinations.height + 10);
        this.addChild(powerpays);
        const doNotPay = new Text(`
        Winning combinations do not pay directly but instead 
        award a certain number of points as follows:
      `, Object.assign(Object.assign({}, paragraphStyle), { wordWrapWidth: width }));
        doNotPay.anchor.set(0.5, 0);
        doNotPay.position.set(width / 2, powerpays.y + powerpays.height);
        this.addChild(doNotPay);
        const symbolsColWidth = width / (Object.keys(Game.Engine.SETTINGS.GAME_SYMBOLS_MAP).length);
        const symbols = new Container();
        symbols.position.set(0, doNotPay.y + doNotPay.height);
        this.addChild(symbols);
        const points = this.points();
        Object
            .keys(Game.Engine.SETTINGS.GAME_SYMBOLS_MAP)
            .forEach((key, index) => {
            const symbol = new SizedContainer(symbolsColWidth, this.height - symbols.y, false);
            symbol.position.set(index * symbolsColWidth, 0);
            const symbolName = Game.Engine.SETTINGS.GAME_SYMBOLS_MAP[key];
            const animatedSprite = Helpers.animatedSpriteFromFrames(`/textures/animations/${symbolName}.json`);
            const scale = Math.min(64 / animatedSprite.width, 64 / animatedSprite.height);
            animatedSprite.anchor.set(0.5);
            animatedSprite.loop = false;
            animatedSprite.animationSpeed = 0;
            animatedSprite.scale.x = animatedSprite.scale.y = scale;
            animatedSprite.position.set(symbol.width / 2, animatedSprite.height / 2);
            const pointsHeading = new H4(`Points`, { fill: "#F5BD46" });
            pointsHeading.anchor.set(0.5, 0);
            pointsHeading.position.set(symbolsColWidth / 2, animatedSprite.height - 16);
            const pointsColOne = new Text(points[0].join("\n"), Object.assign(Object.assign({}, paragraphStyle), { fontSize: 8, align: "left", wordWrap: false, wordWrapWidth: symbolsColWidth }));
            pointsColOne.position.set(10, pointsHeading.y + pointsHeading.height - 8);
            const pointsColTwo = new Text(points[1].join("\n"), Object.assign(Object.assign({}, paragraphStyle), { fontSize: 8, align: "right", wordWrap: false, wordWrapWidth: symbolsColWidth }));
            pointsColTwo.position.set(pointsColOne.x + pointsColOne.width + 10, pointsHeading.y + pointsHeading.height - 8);
            symbol.addChild(animatedSprite);
            symbol.addChild(pointsHeading);
            symbol.addChild(pointsColOne);
            symbol.addChild(pointsColTwo);
            symbols.addChild(symbol);
        });
    }
    points() {
        let currentValue = 250;
        const step = 50;
        const points = [];
        for (let i = 5; i < 50; i++) {
            points.push(`${i} - ${currentValue}`);
            currentValue += step;
        }
        points.reverse();
        const len = points.length;
        const offset = len % 2 === 0 ? 0 : 1;
        return [
            points.slice(0, len / 2 + offset),
            points.slice(len / 2 + offset, len)
        ];
    }
}
