import { DropShadowFilter } from 'pixi-filters';
import { Container } from 'pixi.js';
import { ClickableArea } from './ClickableArea';
import { HorizontalLine, VerticalLine } from './Line';
const shadowFilter = new DropShadowFilter({
    color: 0x000000,
    alpha: 0.25,
    blur: 4,
    offset: { x: 0, y: 4 }
});
export class SettingsArrow extends Container {
    constructor(options) {
        var _a, _b;
        super();
        this.eventMode = 'static';
        this.cursor = 'pointer';
        this.on('pointerdown', (_a = options.callback) !== null && _a !== void 0 ? _a : (() => {
        }));
        this.addChild(new ClickableArea(28, 32));
        const arrow = this.arrow((_b = options.angle) !== null && _b !== void 0 ? _b : 0);
        arrow.position.set(arrow.width / 2 + (this.width / 2 - arrow.width / 2), arrow.height / 2 + (this.height / 2 - arrow.height / 2));
        this.addChild(arrow);
    }
    arrow(angle) {
        const arrow = new Container();
        arrow.addChild(new HorizontalLine(22, 2, { filters: [shadowFilter] }));
        arrow.addChild(new VerticalLine(22, 2, { filters: [shadowFilter] }));
        arrow.pivot.set(arrow.width / 2, arrow.height / 2);
        arrow.angle = angle;
        return arrow;
    }
}
