var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Emitter } from "@pixi/particle-emitter";
import { ActionsContainer, BaseScreen, CONSTANTS, engineGsap, Helpers, MESSAGES, } from "lama-games-engine";
import { Container, Graphics, Point, Sprite, Text } from "pixi.js";
import Game from "../starter";
import { Actions } from "../ui/Actions";
import { BonusMultiplier } from "../ui/BonusMultiplier";
import { ScaleContainer, WrapperContainer } from "../ui/Containers";
import { ReelsContainer } from "../ui/ReelsContainer";
import { SettingsModal } from "../ui/SettingsModal";
import { Sweets } from "../ui/Sweets";
import { WinModal } from "../ui/WinModal";
import { FreeSpinsModal } from "../ui/FreeSpinsModal";
const BACKGROUND_ORIG_WIDTH = 2000;
const BACKGROUND_ORIG_HEIGHT = 2000;
function hideAddressBar() {
    if (!window.location.hash) {
        // @ts-ignore
        if (document.height < window.outerHeight)
            document.body.style.height = window.outerHeight + 50 + "px";
        setTimeout(function () {
            window.scrollTo(0, 1);
            document.body.style.height = "auto";
        }, 500);
    }
}
/* Loads and presents a loading screen of the game */
export class GameScreen extends BaseScreen {
    constructor() {
        super();
        this.insideContainer = new WrapperContainer();
        this.scale = 1;
        this.container = new ScaleContainer();
        this.insideContainer.sortableChildren = true;
        this.reelsContainer = new ReelsContainer();
        this.insideContainer.addChild(this.reelsContainer);
        this.container.addChild(this.insideContainer);
        if (window.PLATFORM.isLandscape && window.PLATFORM.isMobile) {
            this.width = this.container.height;
        }
        else {
            this.width = this.container.width;
        }
        // console.log(this.width)
        this.scale = this.getScale();
        this.container.scale.set(this.scale);
        this.reelsContainer.pivot.set(this.reelsContainer.width / 2, this.reelsContainer.height / 2);
        this.positionReelsContainer();
        this.loadSweets();
        this.createScreen();
        this.loadLogo();
        this.loadBackground();
        this.loadParticles();
        this.gameIdLabel = new Text('', {
            fill: 'white',
            fontSize: 42
        });
        this.prepareGameIdLabel();
        /* Use more space on landscape mobile */
        setTimeout(() => {
            if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
                hideAddressBar();
            }
        }, 1000);
        this.registerEventListeners();
    }
    prepareGameIdLabel() {
        this.gameIdLabel.zIndex = 1000;
        this.gameIdLabel.anchor.set(0.5);
        this.insideContainer.addChild(this.gameIdLabel);
        this.gameIdLabel.alpha = 0.3;
        this.positionGameIdLabel();
    }
    positionGameIdLabel() {
        this.gameIdLabel.y = (window.innerHeight / this.getScale() - this.gameIdLabel.height / 2);
        this.gameIdLabel.x = (window.innerWidth / 2) / this.getScale();
    }
    positionReelsContainer() {
        if (window.PLATFORM.isMobile) {
            if (window.PLATFORM.isPortrait) {
                const centerScreenPoint = new Point(this.windowSize().width / 2 / this.scale, this.windowSize().height / 2 / this.scale);
                this.reelsContainer.x = centerScreenPoint.x;
                this.reelsContainer.y = centerScreenPoint.y;
            }
            if (window.PLATFORM.isLandscape) {
                const centerScreenPoint = new Point(this.windowSize().width / 2 / this.scale, this.windowSize().height / 2 / this.scale);
                this.reelsContainer.x = this.reelsContainer.width / 2;
                this.reelsContainer.y = centerScreenPoint.y + 160 * this.scale;
            }
        }
        else {
            const centerScreenPoint = new Point(this.windowSize().width / 2 / this.scale, this.windowSize().height / 2 / this.scale);
            this.reelsContainer.x = centerScreenPoint.x;
            this.reelsContainer.y = centerScreenPoint.y;
        }
    }
    getScale() {
        var _a;
        if (!window.PLATFORM.isMobile) {
            const mapMaxSize = {
                // '1280': 1024,
                // '1920': 1280,
                // '2560': 1440,
                // '3840': 2800,
                "1280": 800,
                "1920": 800,
                "2560": 800,
                "3840": 800,
            };
            const mapKey = (_a = Object.keys(mapMaxSize).find((key) => {
                return Number(key) >= this.windowSize().width;
            })) !== null && _a !== void 0 ? _a : "1280";
            const windowMaxWidth = Math.min(this.windowSize().width, mapMaxSize[mapKey]);
            const containerMaxWidth = this.width;
            // const windowMaxHeight = this.windowSize().height
            // const containerMaxHeight = Math.min(this.container.width, 600)
            // console.log({windowMaxWidth, containerMaxWidth, windowMaxHeight, containerMaxHeight})
            const scale = //(containerMaxWidth) / 1280
             parseFloat((windowMaxWidth / containerMaxWidth).toFixed(2));
            //   parseFloat((
            //   (this.container.width) > this.windowSize().width
            //     ? this.windowSize().width / (this.container.width)
            //     : (this.container.width) / this.windowSize().width
            // ).toFixed(2))
            return scale;
        }
        if (window.PLATFORM.isLandscape) {
            return parseFloat((this.width > this.windowSize().height
                ? this.windowSize().height / this.width
                : this.width / this.windowSize().height).toFixed(2));
        }
        return parseFloat((this.width > this.windowSize().width
            ? this.windowSize().width / this.width
            : this.width / this.windowSize().width).toFixed(2));
    }
    windowSize() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    loadBackground() {
        this.background = Sprite.from("textures/background.png");
        this.background.anchor.set(0.5);
        this.background.zIndex = 100;
        this.positionBackground();
        engineGsap.to(this.background, {
            pixi: {
                rotation: 360,
            },
            duration: 20,
            repeat: -1,
            ease: "none",
        });
        this.insideContainer.addChild(this.background);
    }
    positionBackground() {
        const { width, height } = this.windowSize();
        const diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
        const scale = Math.max(diagonal / BACKGROUND_ORIG_WIDTH, diagonal / BACKGROUND_ORIG_HEIGHT);
        this.background.position.set(width / 2 / this.scale, height / 2 / this.scale);
        this.background.scale.set(scale / this.scale);
    }
    loadParticles() {
        const { width, height } = this.windowSize();
        if (this.pContainer) {
            this.pContainer.destroy();
        }
        this.pContainer = new Container();
        this.pContainer.pivot.set(this.pContainer.width / 2, this.pContainer.height / 2);
        this.pContainer.position.set(width / 2 / this.scale, height / 2 / this.scale);
        this.pContainer.zIndex = 200;
        const spawnSize = {
            width: width / this.scale,
            height: height / this.scale,
        };
        this.pEmitter = new Emitter(this.pContainer, {
            lifetime: {
                min: 1,
                max: 10,
            },
            frequency: 0.033,
            emitterLifetime: -1,
            maxParticles: 50,
            addAtBack: true,
            pos: { x: 0, y: 0 },
            behaviors: [
                {
                    type: "spawnShape",
                    config: {
                        type: "rect",
                        data: {
                            x: 0 - spawnSize.width / 2,
                            y: 0 - spawnSize.height / 2,
                            w: spawnSize.width,
                            h: spawnSize.height,
                        },
                    },
                },
                {
                    type: "blendMode",
                    config: {
                        blendMode: "add",
                    },
                },
                {
                    type: "alpha",
                    config: {
                        alpha: {
                            list: [
                                { time: 0, value: 1 },
                                { time: 1, value: 0 },
                            ],
                        },
                    },
                },
                {
                    type: "moveSpeed",
                    config: {
                        speed: {
                            list: [
                                { time: 0, value: 5 },
                                { time: 1, value: 0 },
                            ],
                        },
                        minMult: 12,
                    },
                },
                {
                    type: "scale",
                    config: {
                        scale: {
                            list: [
                                { time: 0, value: 0.001 },
                                { time: 1, value: 2 },
                            ],
                        },
                        minMult: 0.5,
                    },
                },
                {
                    type: "color",
                    config: {
                        color: {
                            list: [
                                { time: 0, value: "#e4f9ff" },
                                { time: 1, value: "#3fcbff" },
                            ],
                        },
                    },
                },
                {
                    type: "rotation",
                    config: {
                        accel: 0,
                        minSpeed: 5,
                        maxSpeed: 10,
                        minStart: 0,
                        maxStart: 360,
                    },
                },
                {
                    type: "textureRandom",
                    config: { textures: ["textures/particle.png"] },
                },
            ],
        });
        let elapsed = Date.now();
        const update = () => {
            if (this.pRequestAnimationFrame) {
                cancelAnimationFrame(this.pRequestAnimationFrame);
            }
            this.pRequestAnimationFrame = requestAnimationFrame(update);
            let now = Date.now();
            this.pEmitter.update((now - elapsed) * 0.001);
            elapsed = now;
        };
        this.pEmitter.emit = true;
        update();
        this.insideContainer.addChild(this.pContainer);
    }
    positionParticales() {
        const { width, height } = this.windowSize();
        this.pContainer.pivot.set(this.pContainer.width / 2, this.pContainer.height / 2);
        this.pContainer.position.set(width / 2 / this.scale, height / 2 / this.scale);
        this.pContainer.zIndex = 200;
    }
    positionSweets(sweetsWrap) {
        const reelsBounds = this.reelsContainer.getGlobalPosition();
        const reelsDimensions = this.reelsContainer.getBounds();
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            sweetsWrap.position.x = reelsBounds.x / this.scale;
            sweetsWrap.position.y =
                (reelsDimensions.height / 2 + reelsBounds.y) / this.scale -
                    reelsDimensions.height / this.scale -
                    24;
            return;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            sweetsWrap.position.x = this.windowSize().width / 2 / this.scale;
            sweetsWrap.position.y =
                (reelsDimensions.height / 2 + reelsBounds.y) / this.scale -
                    reelsDimensions.height / this.scale -
                    48;
            return;
        }
        sweetsWrap.position.x = this.windowSize().width / 2 / this.scale;
        sweetsWrap.position.y =
            (reelsDimensions.height / 2 + reelsBounds.y) / this.scale -
                reelsDimensions.height / this.scale -
                48;
    }
    loadSweets() {
        if (this.sweets) {
            this.sweets.destroy();
        }
        this.sweetsWrap = new WrapperContainer();
        const placeholder = new Graphics();
        placeholder.beginFill(0xff0000);
        placeholder.drawRect(0, 0, this.reelsContainer.width, 200);
        placeholder.alpha = 0;
        this.sweetsWrap.zIndex = 500;
        this.sweetsWrap.addChild(placeholder);
        this.sweetsWrap.pivot.set(placeholder.width / 2, 0);
        const sweets = new Sweets();
        this.sweets = sweets;
        sweets.position.set(60, 0);
        this.positionSweets(this.sweetsWrap);
        this.sweetsWrap.addChild(sweets);
        this.insideContainer.addChild(this.sweetsWrap);
        return this;
    }
    createScreen() {
        Game.Engine.ACTIONS_CONTAINER = new ActionsContainer();
        // @ts-ignore
        this.insideContainer.addChild(Game.Engine.ACTIONS_CONTAINER.container);
        Game.Engine.ACTIONS_CONTAINER.container.zIndex = 2000;
        this.ActionsWrapper = new Actions();
        Game.Engine.ACTIONS_CONTAINER.container.addChild(
        // @ts-ignore
        this.ActionsWrapper.actionsContainer);
        this.insideContainer.addChild(this.ActionsWrapper.sliderContainer);
        this.ActionsWrapper.sliderContainer.zIndex = 1000;
        this.positionActions();
        this.positionActionsContainer();
        return;
    }
    positionActions() {
        const actionsMargin = 50;
        const windowSize = {
            width: this.windowSize().width / this.scale,
            height: this.windowSize().height / this.scale,
        };
        this.ActionsWrapper.spinButton.button.sprite.scale.set(1.2);
        this.ActionsWrapper.spinButton.button.sprite.x = windowSize.width / 2;
        this.ActionsWrapper.sliceButton.position.x =
            this.ActionsWrapper.spinButton.button.sprite.x -
                this.ActionsWrapper.spinButton.button.sprite.width -
                actionsMargin / 2;
        this.ActionsWrapper.takeButton.position.x =
            this.ActionsWrapper.spinButton.button.sprite.x +
                this.ActionsWrapper.spinButton.button.sprite.width +
                actionsMargin / 2;
        this.ActionsWrapper.autospinButton.button.sprite.x =
            this.ActionsWrapper.spinButton.button.sprite.x -
                this.ActionsWrapper.spinButton.button.sprite.width / 2 -
                this.ActionsWrapper.autospinButton.button.sprite.width / 2 -
                actionsMargin * 2;
        this.ActionsWrapper.autospinButton.button.sprite.y =
            this.ActionsWrapper.spinButton.button.sprite.y +
                this.ActionsWrapper.spinButton.button.sprite.height / 2 -
                this.ActionsWrapper.autospinButton.button.sprite.height / 2;
        this.ActionsWrapper.speedContainer.x =
            this.ActionsWrapper.spinButton.button.sprite.x +
                this.ActionsWrapper.spinButton.button.sprite.width / 2 +
                this.ActionsWrapper.speedContainer.width / 2 +
                actionsMargin * 2;
        this.ActionsWrapper.speedContainer.y =
            this.ActionsWrapper.spinButton.button.sprite.y +
                this.ActionsWrapper.spinButton.button.sprite.height / 2 -
                this.ActionsWrapper.speedContainer.height / 2;
    }
    positionActionsContainer() {
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            Game.Engine.ACTIONS_CONTAINER.container.scale.set(0.82);
            Game.Engine.ACTIONS_CONTAINER.container.pivot.x =
                this.windowSize().width / 2 / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.x =
                this.windowSize().width / this.scale -
                    Game.Engine.ACTIONS_CONTAINER.container.width / 2;
            Game.Engine.ACTIONS_CONTAINER.container.y =
                this.windowSize().height / this.scale -
                    Game.Engine.ACTIONS_CONTAINER.container.height;
            /* Position Slide Container or container with win bet and plus actions */
            this.ActionsWrapper.sliderContainer.scale.set(0.82);
            const spinButtonPosition = this.ActionsWrapper.spinButton.button.sprite.getGlobalPosition();
            this.ActionsWrapper.sliderContainer.position.x =
                spinButtonPosition.x / this.scale;
            this.ActionsWrapper.sliderContainer.position.y =
                spinButtonPosition.y / this.scale -
                    this.ActionsWrapper.sliderContainer.height - 60;
            return;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            Game.Engine.ACTIONS_CONTAINER.container.scale.set(0.9);
            Game.Engine.ACTIONS_CONTAINER.container.pivot.x =
                this.windowSize().width / 2 / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.x =
                this.windowSize().width / 2 / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.y =
                this.windowSize().height / this.scale -
                    Game.Engine.ACTIONS_CONTAINER.container.height - 40;
            /* Position Slide Container or container with win bet and plus actions */
            this.ActionsWrapper.sliderContainer.scale.set(0.9);
            this.ActionsWrapper.sliderContainer.position.x =
                this.windowSize().width / 2 / this.scale;
            this.ActionsWrapper.sliderContainer.position.y =
                this.reelsContainer.y +
                    this.reelsContainer.height / 2 -
                    this.ActionsWrapper.sliderContainer.height -
                    52;
            return;
        }
        Game.Engine.ACTIONS_CONTAINER.container.scale.set(0.9);
        Game.Engine.ACTIONS_CONTAINER.container.pivot.x =
            this.windowSize().width / 2 / this.scale;
        Game.Engine.ACTIONS_CONTAINER.container.x =
            this.windowSize().width / 2 / this.scale;
        Game.Engine.ACTIONS_CONTAINER.container.y =
            this.windowSize().height / this.scale -
                Game.Engine.ACTIONS_CONTAINER.container.height - 40;
        /* Position Slide Container or container with win bet and plus actions */
        this.ActionsWrapper.sliderContainer.scale.set(0.9);
        this.ActionsWrapper.sliderContainer.position.x =
            this.windowSize().width / 2 / this.scale;
        this.ActionsWrapper.sliderContainer.position.y =
            Game.Engine.ACTIONS_CONTAINER.container.y -
                this.ActionsWrapper.sliderContainer.height -
                68;
        // Game.Engine.ACTIONS_CONTAINER.
    }
    loadLogo() {
        // @ts-ignore
        this.logo = Helpers.animatedSpriteFromFrames("/textures/logo-animated.json");
        this.logo.loop = true;
        this.logo.animationSpeed = 1 / 3;
        this.logo.gotoAndPlay(0);
        this.logo.eventMode = "static";
        this.positionLogo();
        this.logo.zIndex = 3000;
        this.insideContainer.addChild(this.logo);
    }
    positionLogo() {
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            this.logo.scale.set(1.5);
            const spinButtonPosition = this.ActionsWrapper.spinButton.button.sprite.getGlobalPosition();
            const x = 
            // (this.windowSize().width / 2) / this.scale
            spinButtonPosition.x / this.scale;
            const y = 0;
            // Set the anchor point to the center bottom of the logo
            this.logo.anchor.set(0.5, 0);
            this.logo.position.set(x, y);
            return;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            this.logo.scale.set(1.2);
            // Center relative to reels container
            const x = this.windowSize().width / 2 / this.scale;
            // Position right on top of the reels container
            const y = 0;
            // Set the anchor point to the center bottom of the logo
            this.logo.anchor.set(0.5, 0);
            this.logo.position.set(x, y);
            return;
        }
        // Center relative to reels container
        const x = this.windowSize().width / 2 / this.scale;
        // Position right on top of the reels container
        const y = 0;
        // Set the anchor point to the center bottom of the logo
        this.logo.anchor.set(0.5, 0);
        this.logo.position.set(x, y);
    }
    presentSettings(event) {
        return __awaiter(this, void 0, void 0, function* () {
            if (event.action !== "settings") {
                return;
            }
            const settingsModal = new SettingsModal({
                hideTimeoutSeconds: 0,
                hideOnBackdropClick: true,
            });
            yield settingsModal.show();
        });
    }
    removeBonusMultiplier() {
        if (this.counter) {
            this.counter.container.removeFromParent();
        }
    }
    triggerFeature(event) { }
    triggerFreeSpins(event) {
        if (event.action !== CONSTANTS.BUTTON_ACTIONS.SLICE) {
            return;
        }
        const balance = Game.Engine.Application.gameData.balance +
            Game.Engine.Application.gameData.lastWin;
        const bet = Game.Engine.Application.gameData.bet;
        if (balance < bet * 100) {
            return false;
        }
        Game.Engine.updateGameData("balance", balance - bet * 2);
        Game.Engine.updateGameData("lastWin", 0);
        Game.Engine.SOCKET.send(MESSAGES.freeSpins, { bet });
    }
    triggerBonusMultiplier({ reels }) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const sliceButton = (_a = Game.Engine.ACTIONS_CONTAINER) === null || _a === void 0 ? void 0 : _a.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.SLICE);
                if (sliceButton) {
                    sliceButton.setDisabled();
                    (_b = Game.Engine.ACTIONS_CONTAINER) === null || _b === void 0 ? void 0 : _b.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.SPIN).setDisabled();
                    (_c = Game.Engine.ACTIONS_CONTAINER) === null || _c === void 0 ? void 0 : _c.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.AUTO_SPIN).setDisabled();
                    this.counter = new BonusMultiplier({
                        sprite: "/textures/animations/scarab.json",
                        width: 100,
                        height: 100,
                        x: Game.Engine.ACTIONS_CONTAINER.container.x + sliceButton.sprite.x,
                        y: Game.Engine.ACTIONS_CONTAINER.container.y + sliceButton.sprite.y,
                        zIndex: 999,
                    });
                    console.log('HERE!!!');
                    const destinationPosition = Game.Engine.REELS_CONTAINER.reelsView.getGlobalPosition();
                    Game.Engine.updateGameData("multiplier", reels.multiplier || 1);
                    this.counter.appendTo(this.container);
                    yield this.counter.animate({
                        x: destinationPosition.x,
                        y: destinationPosition.y - 60,
                    }, reels.multiplier || 1);
                    Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.GLOBAL_SOCKET_EVENT, {
                        action: CONSTANTS.APPLICATION_EVENTS.SOCKET_EVENTS.SPIN,
                        payload: reels,
                    });
                }
            }
            catch (e) {
                console.log("ERROR:::", e);
            }
        });
    }
    resize(width, height) {
        this.scale = this.getScale();
        this.container.scale.set(this.scale);
        Game.Engine.REELS.forEach((reel, reelIndex) => {
            reel.symbols.forEach((symbol, symbolIndex) => {
                symbol.resize();
            });
        });
        this.reelsContainer.resize();
        this.reelsContainer.pivot.set(this.reelsContainer.width / 2, this.reelsContainer.height / 2);
        this.positionReelsContainer();
        this.loadSweets();
        this.positionActions();
        this.positionActionsContainer();
        this.positionLogo();
        this.positionBackground();
        this.loadParticles();
        this.positionGameIdLabel();
        // Game.Engine.REELS_CONTAINER.resize(_width, _height)
        // Game.Engine.ACTIONS_CONTAINER.resize(_width, _height)
        // const scale = Math.max(_width / BACKGROUND_ORIG_WIDTH, _height / BACKGROUND_ORIG_HEIGHT);
        // this.background.position.set(_width / 2, _height / 2)
        // this.background.scale.set(scale)
        // this.resizeLogo()
        // this.ActionsWrapper.resize(_width, _height)
    }
    show() {
        // @ts-ignore
        Game.Engine.Application.stage.addChild(this.container);
    }
    hide() {
        // @ts-ignore
        Game.Engine.Application.stage.removeChild(this.container);
    }
    onWinPresent(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = WinModal.presentModal(payload.type, {
                hideTimeoutSeconds: 90,
                hideOnBackdropClick: true,
                topOffset: 100,
                scale: this.scale,
                onShow: () => {
                    Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.SOUND_EFFECTS_EVENTS.WIN_MODAL);
                },
                onHide: () => {
                    Game.Engine.setGameMode(payload.nextState);
                },
            });
            if (!modal) {
                return;
            }
            yield modal.show();
        });
    }
    onFreeSpins() {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = FreeSpinsModal.presentModal({
                hideTimeoutSeconds: 90,
                hideOnBackdropClick: true,
                topOffset: 100,
                scale: this.scale,
                onShow: () => {
                    Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.SOUND_EFFECTS_EVENTS.WIN_MODAL);
                },
                onHide: () => {
                    Game.Engine.setGameMode("idle");
                },
            });
            if (!modal) {
                return;
            }
            yield modal.show();
        });
    }
    setGameId(id) {
        console.log('Game Screen ID::', id);
        this.gameIdLabel.text = `Round: ${id}`;
        this.positionGameIdLabel();
    }
    registerEventListeners() {
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, 
        // this.openBuyBonus.bind(this)
        this.triggerFeature.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ANIMATION_EVENTS.HIDE_MULTIPLIER, this.removeBonusMultiplier.bind(this));
        Game.Engine.SOCKET.on(MESSAGES.summonScarabei, this.triggerBonusMultiplier.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, this.presentSettings.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_MODE_CHANGE, ({ mode, payload, }) => __awaiter(this, void 0, void 0, function* () {
            if (mode === "win-present") {
                this.onWinPresent(payload);
                return;
            }
            if (mode === "free-spins") {
                this.onFreeSpins();
            }
        }));
    }
}
