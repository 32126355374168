export const SymbolsMap = {
    1: "banana",
    2: "grape",
    3: "watermelon",
    4: "plum",
    5: "apple",
    6: "peach",
    7: "candy",
    8: "cake",
    9: "lollipop",
    10: "cake_big",
    11: "lolly",
};
const SoundsMap = JSON.parse(process.env.GAME_SOUNDS_MAP || "");
export const SoundOptions = {
    playSound: {
        asset: SoundsMap["playSound"] || "",
        options: {
            volume: 0.5,
            start: 0.3,
            loop: true,
        },
    },
    idleSound: {
        asset: SoundsMap["idleSound"] || "",
        options: {
            volume: 0.3,
            start: 0,
            end: 8,
        },
    },
    wheelsSound: {
        asset: SoundsMap["wheelsSound"] || "",
        options: {
            volume: 0.8,
            start: 0.6,
            speed: 1.4,
        },
    },
    singleWheelSound: {
        asset: SoundsMap["singleWheelSound"] || "",
        options: {
            volume: 0.9,
            start: 0,
            end: 1.75,
            speed: 0.85,
        },
    },
    sliceSound: {
        asset: SoundsMap["sliceSound"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 2.55,
        },
    },
    katanaSound: {
        asset: SoundsMap["katanaSound"] || "",
        options: {
            volume: 0.8,
            start: 0.1,
            end: 0.3,
        },
    },
    winSound: {
        asset: SoundsMap["winSound"] || "",
        options: {
            volume: 1,
            start: 0,
            end: 0.9,
        },
    },
    wildSound: {
        asset: SoundsMap["wildSound"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound1: {
        asset: SoundsMap["scatterSound1"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound2: {
        asset: SoundsMap["scatterSound2"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 1.1,
        },
    },
    scatterSound3: {
        asset: SoundsMap["scatterSound3"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
            end: 2.55,
        },
    },
    bigWinSound: {
        asset: SoundsMap["bigWinSound"] || "",
        options: {
            volume: 1,
            start: 0,
            speed: 1.1,
        },
    }
};
