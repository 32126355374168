import { engineGsap, Helpers } from 'lama-games-engine';
import { BLEND_MODES, Container, NineSlicePlane, Sprite, TilingSprite } from 'pixi.js';
import Game from '../../starter';
import { NineSliceButton } from './NineSliceButton';
export class CandyButton extends NineSliceButton {
    render() {
        this._container.removeChildren();
        this.setCursor();
        const background = this.options.enabled
            ? this.getAnimation()
            : this.getBackground();
        this._container.addChild(background);
        const label = this.getLabel();
        if (label) {
            label.zIndex = 10;
            this._container.addChild(label);
        }
        this
            .centerPivot()
            .addFilters();
    }
    getTile() {
        const spinningSprite = Helpers.getSpriteFromTextureURL(`rope_pink_pattern_tile.png`);
        const tilingScale = this.options.height / spinningSprite.height;
        const tilingSprite = new TilingSprite(spinningSprite.texture, this.options.width, this.options.height);
        tilingSprite.position.set(0, 0);
        tilingSprite.tileScale.set(tilingScale, tilingScale);
        //tilingSprite.blendMode = BLEND_MODES.MULTIPLY;
        return tilingSprite;
    }
    getMask() {
        const maskTexture = Helpers.getSpriteFromTextureURL(`button_bg_mask.png`);
        const slicePlane = new NineSlicePlane(maskTexture.texture, 50, 50, 50, 50);
        slicePlane.width = this.options.width;
        slicePlane.height = this.options.height;
        return new Sprite(Game.Engine.Application.renderer.generateTexture(slicePlane));
    }
    getAnimation() {
        const wrapper = new Container();
        const tilingSprite = this.getTile();
        const mask = this.getMask();
        const background = Helpers.getSpriteFromTextureURL(`button_bg.png`);
        const slicePlane = new NineSlicePlane(background.texture, 50, 50, 50, 50);
        slicePlane.width = this.options.width;
        slicePlane.height = this.options.height;
        slicePlane.blendMode = BLEND_MODES.MULTIPLY;
        wrapper.addChild(tilingSprite);
        wrapper.addChild(slicePlane);
        wrapper.addChild(mask);
        wrapper.mask = mask;
        wrapper.zIndex = 0;
        engineGsap.to(tilingSprite, {
            pixi: {
                tilePositionX: -1200
            },
            duration: 10,
            ease: "power3.out",
            repeat: 0
        });
        return wrapper;
    }
}
