import { Texture } from '@pixi/core';
import { Container, Sprite } from 'pixi.js';
import { engineGsap } from "lama-games-engine";
export class ScreenContainer extends Container {
}
export class WrapperContainer extends Container {
}
export class ReelsViewContainer extends Container {
}
export class ScaleContainer extends Container {
}
export class MultipliersContainer extends Container {
}
export class Multiplier extends Container {
    constructor(x, y) {
        super();
        this.reel = x;
        this.symbol = y;
    }
    setText(text) {
        this.text = text;
        engineGsap.to(this.text, {
            duration: 0.3,
            pixi: {
                scale: 1.2,
            },
            repeat: -1,
            yoyo: true,
            ease: 'power1.inOut',
        });
    }
    setSprite(sprite) {
        this.sprite = sprite;
    }
    setMultiplier(value) {
        if (value === 0) {
            this.text.text = '';
            return;
        }
        this.text.text = value.toString();
    }
}
export class RectangleMask extends Sprite {
    constructor(width = 0, height = 0, texture = Texture.WHITE) {
        super(texture);
        this.width = width;
        this.height = height;
    }
}
