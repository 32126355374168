export default class ButtonActionAdapter {
    constructor(_button, _action) {
        this._button = _button;
        this._action = _action;
        //
    }
    action() {
        return {
            button: {
                action: this._action,
                setEnabled: () => {
                    this._button.enable();
                },
                setDisabled: () => {
                    this._button.disable();
                }
            }
        };
    }
}
