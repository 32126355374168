var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal } from 'lama-games-engine';
import { DropShadowFilter } from 'pixi-filters';
import { Color, Container, Graphics, Sprite, Texture } from 'pixi.js';
import Game from '../starter';
import { ClickableArea } from './ClickableArea';
import { GameRulesFour } from './GameRulesFour';
import { GameRulesOne } from './GameRulesOne';
import { GameRulesThree } from './GameRulesThree';
import { GameRulesTwo } from './GameRulesTwo';
import { SettingsArrow } from './SettingsArrow';
import { SystemSettings } from './SystemSettings';
export class SettingsModal {
    constructor(options) {
        this.options = options;
        this.container = new Container();
        this.currentSlide = 0;
        this.modal = new Modal(options);
        this.initBackground();
        this.initCloseButton();
        this.initArrows();
        this.initSlides();
    }
    setRelativeTo(container) {
        this.relativeTo = container;
        return this;
    }
    initBackground() {
        const rectangle = new Graphics();
        rectangle.beginFill("#270058");
        rectangle.lineStyle(2, "#F5BD46", 1, 0);
        rectangle.drawRoundedRect(0, 0, 1028, 600, 24);
        rectangle.endFill();
        const texture = Game.Engine.Application.renderer.generateTexture(rectangle);
        const sprite = new Sprite(texture);
        const dropShadowFilter = new DropShadowFilter({
            color: (new Color([0, 0, 0])).toNumber(),
            alpha: 0.25,
            blur: 4,
            offset: { x: 0, y: 4 }
        });
        sprite.filters = [dropShadowFilter];
        this.container.addChild(sprite);
    }
    initCloseButton() {
        const shadowFilter = new DropShadowFilter({
            color: 0x000000,
            alpha: 0.25,
            blur: 4,
            offset: { x: 0, y: 4 }
        });
        const container = new Container();
        const linesWrapper = new Container();
        container.addChild(new ClickableArea(36, 36));
        const horizontal = new Graphics();
        horizontal.beginFill("#FFFFFF");
        horizontal.drawRect(0, 17, 36, 2);
        horizontal.endFill();
        horizontal.filters = [shadowFilter];
        linesWrapper.addChild(horizontal);
        const vertical = new Graphics();
        vertical.beginFill("#FFFFFF");
        vertical.drawRect(17, 0, 2, 36);
        vertical.endFill();
        vertical.filters = [shadowFilter];
        linesWrapper.addChild(vertical);
        linesWrapper.pivot.set(linesWrapper.width / 2, linesWrapper.height / 2);
        linesWrapper.x = linesWrapper.width / 2;
        linesWrapper.y = linesWrapper.height / 2;
        linesWrapper.angle = 45;
        container.addChild(linesWrapper);
        container.eventMode = "static";
        container.cursor = "pointer";
        container.on("pointerdown", () => __awaiter(this, void 0, void 0, function* () {
            yield this.hide();
        }));
        container.position.set(this.container.width - container.width - 24, 24);
        this.container.addChild(container);
    }
    initArrows() {
        const next = new SettingsArrow({
            angle: -45,
            callback: () => {
                var _a, _b, _c, _d;
                const slideWidth = ((_b = (_a = this.slides) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : 0) / ((_d = (_c = this.slides) === null || _c === void 0 ? void 0 : _c.children.length) !== null && _d !== void 0 ? _d : 1);
                const next = this.currentSlide - 1;
                if (!this.slides) {
                    return;
                }
                if (next < 0) {
                    return;
                }
                if (!this.slides.getChildAt(next)) {
                    return;
                }
                this.slides.position.x = this.slides.position.x + slideWidth;
                this.currentSlide = next;
            }
        });
        next.position.set(24, this.container.height / 2 - next.height / 2);
        const prev = new SettingsArrow({
            angle: 135,
            callback: () => {
                var _a, _b, _c, _d;
                const slideWidth = ((_b = (_a = this.slides) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : 0) / ((_d = (_c = this.slides) === null || _c === void 0 ? void 0 : _c.children.length) !== null && _d !== void 0 ? _d : 1);
                const prev = this.currentSlide + 1;
                if (!this.slides) {
                    return;
                }
                if (prev > (this.slides.children.length - 1)) {
                    return;
                }
                if (!this.slides.getChildAt(prev)) {
                    return;
                }
                this.slides.position.x = this.slides.position.x - slideWidth;
                this.currentSlide = prev;
            }
        });
        prev.position.set(this.container.width - prev.width - 24, this.container.height / 2 - prev.height / 2);
        this.container.addChild(next);
        this.container.addChild(prev);
    }
    initSlides() {
        const horizontalOffset = 100;
        const verticalOffset = 50;
        const width = this.container.width - (2 * horizontalOffset);
        const height = this.container.height - (2 * verticalOffset);
        const container = new Container();
        container.position.set(horizontalOffset, verticalOffset);
        this.container.addChild(container);
        const settings = new Container();
        container.addChild(settings);
        const systemSettings = new SystemSettings(width, height);
        systemSettings.position.set(0, 0);
        const rules1 = new GameRulesOne(width, height);
        rules1.position.set(systemSettings.x + systemSettings.width, systemSettings.y);
        const rules2 = new GameRulesTwo(width, height);
        rules2.position.set(rules1.x + rules1.width, rules1.y);
        const rules3 = new GameRulesThree(width, height);
        rules3.position.set(rules2.x + rules2.width, rules2.y);
        const rules4 = new GameRulesFour(width, height);
        rules4.position.set(rules3.x + rules3.width, rules3.y);
        this.slides = settings;
        settings.addChild(systemSettings);
        settings.addChild(rules1);
        settings.addChild(rules2);
        settings.addChild(rules3);
        settings.addChild(rules4);
        const mask = new Sprite(Texture.WHITE);
        mask.width = systemSettings.width;
        mask.height = systemSettings.height;
        mask.position.set(0, 0);
        container.addChild(mask);
        container.mask = mask;
    }
    show() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this
                .modal
                .addContentChild(this.container, this.relativeTo)
                .show();
        });
    }
    hide() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.modal.hide();
        });
    }
}
