import { engineGsap } from 'lama-games-engine';
import { Container, Text } from 'pixi.js';
import ButtonActionAdapter from './ButtonActionAddapter';
import { labelStyles, options as defaultOptions } from './ButtonDefaults';
export class AbstractButton {
    get container() {
        this.render();
        return this._container;
    }
    get isEnabled() {
        var _a;
        return (_a = this.options.enabled) !== null && _a !== void 0 ? _a : false;
    }
    set isEnabled(value) {
        this.options.enabled = value;
        this.render();
    }
    get position() {
        return this._container.position;
    }
    set position(value) {
        this._container.position = value;
    }
    get width() {
        return this._container.width;
    }
    get height() {
        return this._container.height;
    }
    constructor(options) {
        this._container = new Container();
        this.options = Object.assign(Object.assign({}, defaultOptions), options);
        this._container.sortableChildren = true;
        if (this.options.clickHandler) {
            this._container.addListener('pointerdown', (event) => {
                event.stopPropagation();
                event.preventDefault();
                this.onClick();
            });
        }
    }
    enable() {
        if (this.options.enabled) {
            return this;
        }
        return this.toggleEnabled();
    }
    disable() {
        if (!this.options.enabled) {
            return this;
        }
        return this.toggleEnabled();
    }
    toggleEnabled() {
        this.isEnabled = !this.isEnabled;
        return this;
    }
    onClick() {
        if (!this.options.clickHandler)
            return;
        if (!this.options.enabled)
            return;
        const timeline = engineGsap.timeline({
            repeat: 0
        });
        timeline
            .add(engineGsap.to(this._container.scale, {
            x: 1.03,
            y: 1.03,
            duration: 0.1,
            ease: "power1.in"
        }))
            .add(engineGsap.to(this._container.scale, {
            x: 1,
            y: 1,
            duration: 0.1,
            ease: "power1.out"
        }), ">");
        this.options.clickHandler(this);
    }
    setCursor() {
        if (this.options.enabled) {
            this._container.interactive = true;
            this._container.cursor = 'pointer';
        }
        else {
            this._container.interactive = false;
            this._container.cursor = 'not-allowed';
        }
        return this;
    }
    centerPivot() {
        this._container.pivot.set(this._container.width / 2, this._container.height / 2);
        return this;
    }
    setLabel(label) {
        this.options.label = label;
        this.render();
    }
    getLabel() {
        if (!this.options.label) {
            return null;
        }
        const styles = labelStyles;
        if (this.options.enabled) {
            styles.fill = "#54005d";
            delete styles.fillGradientStops;
            delete styles.fillGradientType;
        }
        const label = new Text(this.options.label, styles);
        label.anchor.set(0.5);
        label.position.set(this._container.width / 2, this._container.height / 2);
        label.zIndex = 100;
        return label;
    }
    addFilters() {
        this._container.alpha = this.options.enabled ? 1 : 0.5;
        return this;
    }
    render() {
        throw new Error('AbstractButton#render method is not implemented!');
    }
    /**
     * TODO: This is hack :D
     * @param action
     */
    toAction(action) {
        return (new ButtonActionAdapter(this, action)).action();
    }
}
