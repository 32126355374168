import { NumberControl } from 'lama-games-engine';
import { Container } from 'pixi.js';
import { H2 } from './Headings';
import Game from "../starter";
export class BetSettings {
    constructor() {
        this.container = new Container();
        const title = new H2(`Total bet`, { fill: "#F5BD46" });
        title.anchor.set(0.5);
        const numberButton = new NumberControl('total_bet', Game.Engine.Application.gameData.bet, {
            prefix: '$'
        });
        numberButton.setOnChange((name, value, control) => {
            if (value.current)
                Game.Engine.updateGameData('bet', value.current);
        });
        this.container.addChild(numberButton.getContainer());
        this.container.addChild(title);
        title.position.set(numberButton.getContainer().width / 2, numberButton.getContainer().height - title.height - 24);
    }
}
