import { Container, Text, TextStyle } from "pixi.js";
import Game from "../starter";
/* Loads and presents a loading screen of the game */
export class TemplateScreen {
    constructor() {
        this.container = new Container();
        this.createScreen();
    }
    createScreen() {
        const style = new TextStyle({
            fontFamily: 'Bely Display',
            fontSize: 36,
            fontWeight: 'bold',
            fill: '#ffffff',
        });
        const text = new Text('Template Screen...', style);
        text.x = (Game.Engine.Application.screen.width - text.width) / 2;
        text.y = (Game.Engine.Application.screen.height - text.height) / 2;
        text.eventMode = 'static';
        text.addEventListener('pointerdown', () => {
            Game.Engine.Navigator.screensHandler({ key: 'GameScreen', action: 'show' });
        });
        this.container.addChild(text);
    }
    show() {
        Game.Engine.Application.stage.addChild(this.container);
    }
    hide() {
        Game.Engine.Application.stage.removeChild(this.container);
    }
}
