import * as Screens from './screens';
import { GameEngine } from 'lama-games-engine';
import { SymbolsMap, SoundOptions } from "./config";
const mobileTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
window.PLATFORM = {
    isMobile: mobileTest,
    isLandscape: window.innerWidth > window.innerHeight,
    isPortrait: window.innerHeight > window.innerWidth,
};
const GAME_ENVS = process.env;
const gameConfig = Object.keys(GAME_ENVS).filter(key => key.startsWith('GAME_')).reduce((acc, curr) => {
    try {
        acc[curr] = JSON.parse(GAME_ENVS[curr] || "");
    }
    catch (e) {
        acc[curr] = GAME_ENVS[curr];
    }
    return acc;
}, {});
const Game = new GameEngine(Object.assign(Object.assign({}, gameConfig), { GAME_SCREENS: Screens, GAME_SYMBOLS_MAP: SymbolsMap, GAME_SOUND_OPTIONS: SoundOptions }));
export default Game;
