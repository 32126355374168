import { CONSTANTS, ToggleControl } from 'lama-games-engine';
import { Container, Text, TextStyle } from 'pixi.js';
import { H2 } from './Headings';
import Game from "../starter";
const toggleable = [
    {
        name: 'quick_spin',
        heading: 'Quick spin',
        text: `Play faster by reducing total spin time`,
        wordWrapWidth: 190
    },
    {
        name: 'battery_saver',
        heading: 'Battery saver',
        text: `Save battery life by reducing animation speed`,
        wordWrapWidth: 264
    },
    {
        name: 'ambient_music',
        heading: 'Ambient music',
        text: `Turn ON or OFF the game music`,
        wordWrapWidth: 264
    },
    {
        name: 'sound_fx',
        heading: 'Sound FX',
        text: `Turn ON or OFF the game sounds`,
        wordWrapWidth: 264
    }
];
export class SystemSettingsControls {
    constructor() {
        this.container = new Container();
        toggleable
            .map((options) => {
            return this.create(options);
        })
            .forEach((toggleable, index) => {
            toggleable.position.set(0, this.container.height + (index ? 16 : 0));
            this.container.addChild(toggleable);
        });
    }
    position(x, y) {
        this.container.position.set(x, y);
    }
    create(options) {
        const container = new Container();
        const toggleButton = new ToggleControl(options.name, Game.Engine.Application.gameData[options.name]);
        const heading = new H2(options.heading);
        const text = new Text(options.text, new TextStyle({
            dropShadow: true,
            dropShadowAlpha: 0.25,
            dropShadowAngle: 90,
            dropShadowBlur: 4,
            dropShadowDistance: 4,
            dropShadowColor: "#000000",
            fill: "#ffffff",
            fontFamily: 'Poppins',
            fontWeight: 'normal',
            fontSize: 16,
            lineJoin: "round",
            stroke: "#000000",
            strokeThickness: 1,
            whiteSpace: "normal",
            wordWrap: true,
            wordWrapWidth: options.wordWrapWidth
        }));
        const columnOffset = 14;
        const secondColumnOffset = toggleButton.getContainer().width + columnOffset;
        toggleButton.getContainer().position.set(0, columnOffset / 2);
        heading.position.set(secondColumnOffset, 0);
        heading.style.fill = toggleButton.getValue() ? "#F5BD46" : "#9592BA";
        text.position.set(secondColumnOffset, heading.height + columnOffset / 2);
        toggleButton.setOnChange((name, value, control) => {
            Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.BASE_EVENTS.SETTINGS, { action: name, payload: value.current });
            Game.Engine.updateGameData(name, value.current);
            heading.style.fill = value.current ? "#F5BD46" : "#9592BA";
        });
        container.addChild(toggleButton.getContainer(), heading, text);
        return container;
    }
}
